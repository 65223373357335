@charset "utf-8";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../../node_modules/bulma/bulma.sass";
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#wrapper {
    flex: 1;
}

.logo {
    font-size: 24pt;
    font-family: 'Pacifico', cursive;
    span {
        color: #8e1e1d;
    }
}

.navbar {
    border-bottom: 1px solid rgba(127, 128, 129, 0.287);
    box-shadow: 0px -4px 13px 0px rgba(0,0,0,0.73);
    background-color: #ecebeb;
}

.nav_divider {
    width: 1px;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(142, 30, 29, 1), rgba(0, 50, 75, 0));
}

#content {
    padding-top: 25px;
}

.date, .category, .email {
    padding-bottom: 0;
}

#submit {
    background-color: #2B3D52;
}

.is-danger-selector {
    border-color: #f14668!important;
}

.stats .content {
    text-align: center;
    .stat {
        font-size: 24pt;
    }

    ul {
        margin-left: 0;
        list-style-type: none;
        text-align: left;
    }
}

.nav_divider_hor {
    height: 1px;
    width: 100%;
    background: linear-gradient(to left, rgba(255, 0, 0, 0), rgba(142, 30, 29, 1), rgba(0, 50, 75, 0));
}

.nav_large {
    margin: 16px;
    background-color: #2B3D52;
    //background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(43, 61, 82, 1), rgba(0, 50, 75, 0));
}

.is-linkedin, .is-facebook {
    float: right;
    margin: 0 5px;
}

// @media only screen and (max-width: 768px) {
//     .date {
//         padding-top: 0;
//     }
// }

